.not-lazy img {
    width: 100%;
}
.lazy {
    background-color: rgba($black, 0.1);
    display: inline-block;
    position: relative;
    width: 100%;
    transition: background-color 0.3s $anim-curve;
}

.lazy img {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s $anim-curve;
    margin-bottom: 0;
}

.lazy.loaded {
    background-color: transparent;

    img {
        opacity: 1;
    }
}

.iframe {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;

    iframe,
    object,
    embed,
    lite-youtube {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    &[data-iframe^="https://www.youtube.com/"]
    {
        & > iframe {
            width: 0;
            height: 0;
        }
    }
}

.object-fit {
    padding-bottom: 0 !important;

    img {
        object-fit: cover;
    }
}
