.ladder {
    height: 100%;
    position: relative;
    z-index: 20;

    @media (min-width: 800px) {
        img {
            object-fit: cover;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            right: 0;
            height: 100%;

            transform: translateX(-50%);
        }
    }
}
