h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $type-title-font;
    font-weight: 600;
}

.omega {
    @include omega;
}

h1,
.alpha {
    @include alpha;
}

h2,
.beta {
    @include beta;
}

h3,
.gamma {
    @include gamma;
}

h4,
.delta,
.standfirst {
    @include delta;
}

h5,
.epsilon {
    @include epsilon;
}

h6 {
    @include base;
    text-transform: uppercase;
    text-decoration: underline;
}

.zeta {
    @include zeta;
}

.iota {
    @include iota;
}

.base {
    @include base;
}

.bold {
    font-weight: $bold;
}

.link {
    color: $secondary;
    font-weight: $bold;

    &:hover,
    &:focus {
        color: $primary;
    }
}

.subtitle {
    text-transform: uppercase;
    display: inline-block;
    border-bottom: 1px solid;
}

.underline {
    display: inline-block;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid;
}

.standfirst a {
    @extend .link;
}

.font-body {
    font-family: $type-body-font;
}
