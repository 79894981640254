//Spacing
.no-margin {
    margin: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-left {
    padding-left: 0 !important;
}

//position

.pr {
    position: relative;
}

// Widths

.w-25 {
    width: 25%;
}

.w-33 {
    width: 33.33333%;
}

.w-50 {
    width: 50%;
}

.w-66 {
    width: 66.66666%;
}

.w-75 {
    width: 75%;
}

.w-100 {
    width: 100%;
}

// hr

.hr {
    color: transparent;
    background: transparent;
    border: 0;
    height: 1px;
    background-color: $grey;
}

.hr--white {
    background-color: $white;
}

.noscroll {
    overflow: hidden;
}

@media (max-width: 960px) {
    .xl-push-none {
        margin-left: 0;
    }

    .push-eighth {
        margin-left: 0;
    }
}

.sr {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.hide {
    display: none;
}

// Flex

.fx-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fx-justify-center {
    display: flex;
    justify-content: center;
}

.fx-align-center {
    display: flex;
    align-items: center;
}
