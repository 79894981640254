@charset 'UTF-8';

// BASE
@import '~modern-normalize/modern-normalize.css';

@import 'config';
@import 'variables';
@import 'mixins';

@import 'typography/mechanics';
@import 'typography/base';
@import 'typography/scale';

// Vendor
/*
 * Example :
 * @import '~npm-module-name/_vendor'
*/
// @import '~baguettebox.js/dist/baguetteBox.min.css';
@import '~vanilla-cookieconsent/dist/cookieconsent.css';

// Generic
@import 'globals/animation.scss';
@import 'globals/base.scss';
@import 'globals/button.scss';
@import 'globals/fitvids.scss';
@import 'globals/fonts.scss';
@import 'globals/footer.scss';
@import 'globals/form.scss';
@import 'globals/grid.scss';
@import 'globals/header.scss';
@import 'globals/icon.scss';
@import 'globals/layouts.scss';
@import 'globals/lazyloading.scss';
@import 'globals/markup.scss';
@import 'globals/skins.scss';
@import 'globals/type.scss';

// Blocks
// @import './../../../modules/blocks/**/style.scss';

// Components
@import './../../../../modules/components/clouds/style.scss';
@import './../../../../modules/components/ladder/style.scss';
@import './../../../../modules/components/logo/style.scss';

// Trumps
@import 'trumps/utilities';
@import 'trumps/cookie-consent';
@import 'trumps/shame';
@import 'trumps/ie';

.front-page {
    padding-top: rhythm(16);
}
