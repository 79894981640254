// Size - creates width, height properties with same value
// @include size(10px) = width: 10px; height: 10px;
// @include size(10px, 20px) = width: 10px; height: 20px;
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

// 1 line positions
// @include position(absolute, 10px 20px); // position: absolute; top: 10px; right: 20px; bottom: 10px; left; 20px;
@mixin position($position, $values) {
    position: $position;

    // top is always the first value
    $top: nth($values, 1);

    // by default the rest will follow
    $right: $top;
    $bottom: $top;
    $left: $top;

    // second value is for both left/right
    @if length($values) > 1 {
        $left: nth($values, 2);
        $right: $left;
    }

    // third is for bottom
    @if length($values) > 2 {
        $bottom: nth($values, 3);
    }

    // fourth is for left
    @if length($values) > 3 {
        $left: nth($values, 4);
    }

    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
}

// Depth
// Source: https://css-tricks.com/snippets/sass/material-shadows-mixin/
@function top-shadow($depth) {
    $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
    $blur: nth(1.5 3 10 14 19, $depth) * 4px;
    $color: rgba(black, nth(0.12 0.16 0.19 0.25 0.3, $depth));

    @return 0 $primary-offset $blur $color;
}

@function bottom-shadow($depth) {
    $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
    $blur: nth(1 3 3 5 6, $depth) * 4px;
    $color: rgba(black, nth(0.24 0.23 0.23 0.22 0.22, $depth));

    @return 0 $primary-offset $blur $color;
}

@mixin depth($depth) {
    @if $depth < 1 {
        box-shadow: none;
    } @else if $depth > 5 {
        @warn "Invalid $depth `#{$depth}`.";
    } @else {
        box-shadow: bottom-shadow($depth), top-shadow($depth);
    }
}

// Placeholder
// Source: https://css-tricks.com/almanac/selectors/p/placeholder/
@mixin placeholder($color) {
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $color;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        color: $color;
    }

    &:-ms-input-placeholder {
        /* IE 10+ */
        color: $color;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        color: $color;
    }
}

@mixin type($font-size: $base-font-size, $line-height: 4) {
    font-size: $font-size * 1px;
    font-size: calc($font-size / $base-font-size) * 1rem;
    line-height: ($type-rhythm * $line-height) * $base-font-size * 1px;
    line-height: $type-rhythm * $line-height * 1rem;
}

@function setActiveColor($color) {
    @if (lightness($color) <= 30) {
        @return lighten($color, 10);
    } @else {
        @return darken($color, 10);
    }
}

// Usage: @include link($primary, $secondary, true);
@mixin link($color: $black, $hover-color: $primary, $underline: false) {
    @if $color {
        color: $color;
    }

    @if $underline {
        text-decoration: underline;
    }

    &:hover,
    &:focus {
        color: $hover-color;

        @if $underline {
            text-decoration: none;
        }
    }

    &:active {
        @if $hover-color {
            color: setActiveColor($hover-color);
        } @else {
            color: setActiveColor($color);
        }
    }
}
