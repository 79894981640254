:root {
    --button-border-width: 2px;
    --button-border-radius: 5px;
}

button {
    cursor: pointer;
    // @include base;
    color: inherit;
}

.button {
    color: var(--black);
    background-color: transparent;
    display: inline-block;
    position: relative;
    border: var(--button-border-width) solid var(--black);
    border-radius: var(--button-border-radius);
    font-weight: var(--bold);
    text-align: center;
    cursor: pointer;
    padding: var(--rhythm-1) var(--rhythm-4);
    outline: none;
    transition: color var(--anim-time) var(--anim-curve),
        background-color var(--anim-time) var(--anim-curve);

    &:hover,
    &:focus {
        color: var(--white);
        background-color: var(--primary);
    }

    &:active {
        transform: translateY(2px);
    }

    &[disabled] {
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        cursor: default;
        transform: none;
        opacity: 0.1;
    }
}

// Variants

.button--alt {
    color: var(--white);
    background-color: var(--primary);

    &:hover,
    &:focus {
        color: var(--primary);
        background-color: transparent;
    }
}
