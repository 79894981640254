.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @extend .row;
    z-index: $z-level-7;

    @media (max-width: 800px) {
        padding: 0;
    }
}

.header-media {
    width: 100%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $black;
    z-index: $z-under;
    opacity: 0;
    transform: scale(1.1);
    transition: transform $anim-time $anim-curve, opacity $anim-time $anim-curve;

    &.is-active {
        z-index: $z-level-8;
        transform: scale(1);
        transition: transform $anim-time $anim-curve,
            opacity $anim-time $anim-curve, z-index 0 $anim-curve $anim-time;
        opacity: 0.5;
    }
}
