:root {
    // Colours
    --black: #{$black};
    --white: #{$white};
    --grey: #{$grey};

    --blue: #{$blue};
    --green: #{$green};
    --red: #{$red};

    --primary: #{$primary};
    --secondary: #{$secondary};
    --bg: #{$white};

    --text-shadow: #{$text-shadow};

    // Layering
    --z-under: #{$z-under};
    --z-level-0: #{$z-level-0}; // Page
    --z-level-1: #{$z-level-1}; // Page
    --z-level-2: #{$z-level-2}; // Page
    --z-level-3: #{$z-level-3}; // Page
    --z-level-4: #{$z-level-4}; // Alerts
    --z-level-5: #{$z-level-5}; // Navigation
    --z-level-6: #{$z-level-6}; // Header
    --z-level-7: #{$z-level-7}; // Takeover
    --z-level-8: #{$z-level-8}; // Flyout
    --z-level-9: #{$z-level-9}; // Toast
    --z-level-10: #{$z-level-10}; // System

    // Type setting
    --type-body-font: #{$type-body-font};
    --type-title-font: #{$type-title-font};

    --bold: #{$bold};

    // Transitions
    --anim-time: #{$anim-time};
    --anim-curve: #{$anim-curve};

    // Hero
    --hero-height: #{$hero-height};

    // Grid
    --grid-width: #{$grid-width};
}
