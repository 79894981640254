/*
Grid.css
A responsive grid by jjgrainger
http://jjgrainger.co.uk/grid
 */

.row,
.column {
    box-sizing: border-box;
}
// Grid

.row {
    display: flex;
    flex-flow: row wrap;
    max-width: $grid-width;
    padding-right: rhythm(2);
    padding-left: rhythm(2);
    margin-right: auto;
    margin-left: auto;
}

.row .row {
    // margin: 0 -.75em -1.5em;
    margin: rhythm(-2);
}

.column {
    // float: left;
    // padding: 0 .75em rhythm(4);
    padding: rhythm(2);
}

/* whole */
.column.one-whole {
    width: 100%;
}

/* halves */
.column.one-half {
    width: 50%;
}

/* thirds */
.column.one-third {
    width: 33.333%;
}

.column.two-thirds {
    width: 66.666%;
}

/* fourths */
.column.one-fourth {
    width: 25%;
}

.column.three-fourths {
    width: 75%;
}

/* fifths */
.column.one-fifth {
    width: 20%;
}

.column.two-fifths {
    width: 40%;
}

.column.three-fifths {
    width: 60%;
}

.column.four-fifths,
.four-fifths {
    width: 80%;
}

/* sixths */
.column.one-sixth {
    width: 16.666666667%;
}

.column.five-sixths {
    width: 83.333333335%;
}

/* twelfths */
.column.one-twelfth {
    width: 8.3333333%;
}

.column.five-twelfths {
    width: 41.6666667%;
}

.column.seven-twelfths {
    width: 58.3333333%;
}

.column.eleven-twelfths {
    width: 91.666666667%;
}

/* push */
.push-twelfth {
    margin-left: 8.3333333%;
}

.push-sixth {
    margin-left: 16.666666667%;
}

.push-third {
    margin-left: 33.33333%;
}

.push-fourth {
    margin-left: 25%;
}

/* float right */
.flow-reverse {
    float: right;
}

/*
Cleafix
Micro Clearfix by Nicolas Gallagher
http://nicolasgallagher.com/micro-clearfix-hack/
 */
.cf {
    overflow: visible !important;
}

.cf::before,
.cf::after {
    content: ' ';
    display: block;
}

.cf::after {
    clear: both;
}

/*
For IE 6/7 only
Include this rule to trigger hasLayout and contain floats.
 */
.cf {
    *zoom: 1;
}

.l-show {
    display: none;
}

/* xxl */
@media (max-width: 1024px) {
    .row {
        // max-width: 960px;
    }

    .column.xxl-one-half {
        width: 50%;
    }
}

/* xl */
@media (max-width: 960px) {
    .row {
        // max-width: 800px;
    }

    .column.xl-one-whole {
        width: 100%;
        margin: 0;
    }

    .column.xl-one-half {
        width: 50%;
        margin: 0;
    }

    .column.xl-one-third {
        width: 33.333%;
        margin: 0;
    }
}

/* l */
@media (max-width: 800px) {
    .row {
        // max-width: 90%;
        padding: 0 rhythm(2);
    }

    .column {
        margin: 0;
    }

    .row .row {
        // max-width: none;
    }

    .l-hide {
        display: none !important;
    }

    .l-show {
        display: block;
    }

    .column.l-one-whole,
    .l-one-whole {
        margin: 0;
        width: 100%;
    }

    .column.l-one-half {
        width: 50%;
    }

    .column.l-one-third {
        width: 33.333%;
    }

    .column.l-two-thirds {
        width: 66.666%;
    }

    .l-centered {
        margin-left: auto;
        margin-right: auto;
        float: none;
    }
}

/* m */
@media (max-width: 600px) {
    .column.m-one-whole {
        margin: 0;
        width: 100%;
    }

    .column.m-one-half {
        width: 50%;
    }

    .m-centered {
        margin-left: auto;
        margin-right: auto;
        float: none;
    }
}

/* s */
@media (max-width: 480px) {
    .s-hide {
        display: none;
    }

    .column.s-one-whole {
        margin: 0;
        width: 100%;
    }
}

/* xs */
@media (max-width: 320px) {
}
