/*
    <div class="form-row">
        <div class="input">
            <label class="input--label" for="select-input">Select Element</label>
            <div class="input--select">
                <select name="select-input" id="select-input">
                    <option value="1">Option 1</option>
                    <option value="2">Option 2</option>
                    <option value="1">Option 3</option>
                </select>
            </div>
        </div>

        <div class="input">
            <label for="text-input">Text Input</label>
            <input class="input-field" type="text" name="text-input" id="text-input" placeholder="Example ..." value="">
        </div>
    </div>

    <div class="form-row">
        <div class="input input--checkbox">
            <input type="checkbox" name="consent" id="checkbox" value="">
            <label for="checkbox">Subscribe</label>
        </div>
    </div>
 */

.form {
    margin-bottom: rhythm(4);

    &:last-child {
        margin-bottom: 0;
    }
}

.form-row {
    display: flex;
    margin-bottom: rhythm(2);

    .input {
        flex: 1;
        margin-right: rhythm(2);

        &:last-child {
            margin-right: 0;
        }

        @media (max-width: 800px) {
            margin-right: 0;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: 800px) {
        flex-direction: column;
    }
}

input,
select,
textarea {
    font-family: $type-body-font;
    outline: none;
    color: inherit;
}

select {
    cursor: pointer;
    color: inherit;
}

.input {
    background-color: transparent;

    &:focus-within .input-field {
        border-color: $yellow;
    }
}

.input-field {
    color: $black;
    background-color: transparent;
    width: 100%;
    border: 2px solid $black;
    padding: rhythm(1);
    outline: none;
    @include base;
    @include placeholder($black);
}

label {
    display: block;
    text-align: left;
    font-weight: $bold;

    a {
        text-decoration: underline;
    }
}

// Variants

.input--checkbox {
    background-color: transparent !important;
    position: relative;
    border: none;
    min-height: rhythm(4);
    padding: 0 0 0 rhythm(6) !important;

    input[type='checkbox'] {
        position: absolute;
        top: rhythm(2);
        left: rhythm(1);
        width: rhythm(3);
        opacity: 0;
        margin: 0;

        &:checked + label:after {
            content: '';
        }
    }

    input:focus ~ label:before {
        border: 3px solid;
    }

    label {
        cursor: pointer;
        @include iota;
        padding-top: rhythm(0.5);

        &:before {
            content: '';
            border: 2px solid $black;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            @include size(#{rhythm(4)});
        }

        &:after {
            position: absolute;
            top: rhythm(1.1);
            left: rhythm(1);
            width: rhythm(2);
            height: rhythm(1.2);
            border-left: 4px solid $primary;
            border-bottom: 4px solid $primary;
            transform: rotate(-45deg);
        }
    }
}

.input--select {
    position: relative;

    input,
    select {
        display: block;
        position: relative;
        z-index: 1;
        padding: 0 rhythm(4) 0 0;
        appearance: none;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: rhythm(2);
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rhythm(0.5) rhythm(0.5) 0 rhythm(0.5);
        border-color: $black transparent transparent transparent;
        margin: auto;
    }
}
