$base-font-size: 16 !default;
$type-body: $base-font-size * 1px !default;

:root {
    --type-body: #{$type-body};
}

$target-line-height: 24 !default;

// rhythm
// best to have increments for more control adjusting
$type-leading: calc($target-line-height / $base-font-size) !default;
$line-height: $type-leading * 1rem !default;
$type-rhythm: calc($type-leading / 4) !default;

/*  
 * Set the vertical rhythm of the page on paddings, margins etc.
 * @param Integer - An increment of the scale to use
 * @return String - scale value in rems
 */
@function rhythm($num) {
    @return $type-rhythm * $num * 1rem;
}

:root {
    --rhythm-1: #{rhythm(1)};
    --rhythm-2: #{rhythm(2)};
    --rhythm-4: #{rhythm(4)};
    --rhythm-6: #{rhythm(6)};
    --rhythm-8: #{rhythm(8)};
}

@mixin type($font-size: $base-font-size, $line-height: 4) {
    font-size: $font-size * 1px;
    font-size: calc($font-size / $base-font-size) * 1rem;
    line-height: ($type-rhythm * $line-height) * $base-font-size * 1px;
    line-height: $type-rhythm * $line-height * 1rem;
}
