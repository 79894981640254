[data-animate] {
    opacity: 0;

    &.visible {
        opacity: 1;
        transition: all 2s $anim-curve;
    }
}

[data-animate='slideDown'] {
    transform: translateY(-20%);

    &.visible {
        transform: none;
    }
}

[data-animate='slideUp'] {
    transform: translateY(20%);

    &.visible {
        transform: none;
    }
}

[data-animate='slideRight'] {
    transform: translateX(-10%);

    &.visible {
        transform: none;
    }
}

[data-animate='slideLeft'] {
    transform: translateX(10%);

    &.visible {
        transform: none;
    }
}
