// Footer

.footer {
    color: $black;
    background-color: #f8f8f8;

    a {
        @include link($black);
    }

    .button {
        @extend .button--alt;
    }

    .input--checkbox label:before {
        border-color: $black;
    }
}

.footer-title {
    font-weight: $bold;
    font-family: $type-body-font;
    @include base;
}
