// IE

// Display unsupported browser message for < IE 11
// Source: https://css-tricks.com/ie-10-specific-styles/

html[data-useragent*='MSIE'] .unsupported_browser {
	display: block;
}

// > IE 9 fixes
// Source: https://stackoverflow.com/questions/28417056/how-to-target-only-ie-any-version-within-a-stylesheet

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.card,
	.card-content {
		display: block;
	}
}
