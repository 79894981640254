// Colours

.white {
    color: $white;
}

.black {
    color: $black;
}

.grey {
    color: $grey;
}

// Background

.bg--white {
    background-color: $white;
}

.bg--grey {
    background-color: $grey;
}

.bg--primary {
    background-color: $primary;
}
