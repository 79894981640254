// rhythm
// set the line-height
// make sure that this is target leading in px / base point size
$base-font-size: 18;
$target-line-height: 28;

// Colours
$black: #000;
$white: #fff;
$grey: #e5e5e5;

$blue: #4299e1;
$green: #48bb78;
$red: #f56565;

$cream: #fff8f4;

$yellow: #fcbe24;

$primary: #111;
$secondary: $blue;
$bg: $cream;

$text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);

// Layering
$z-under: -1;
$z-level-0: 0; // Page
$z-level-1: 100; // Page
$z-level-2: 200; // Page
$z-level-3: 300; // Page
$z-level-4: 400; // Alerts
$z-level-5: 500; // Navigation
$z-level-6: 600; // Header
$z-level-7: 700; // Takeover
$z-level-8: 800; // Flyout
$z-level-9: 900; // Toast
$z-level-10: 9999; // System

// Type setting
$type-title-font: 'STIX Two Text', serif;
$type-body-font: 'Figtree', sans-serif;
$bold: 600;

// Transitions
$anim-time: 0.2s;
$anim-curve: cubic-bezier(0.4, 0, 0.2, 1);

// Hero
$hero-height: 70vh;

// Grid
$grid-width: 1400px;

// rhythm
// set the line-height
// make sure that this is target leading in px / base point size
$base-font-size: 18;
$target-line-height: 28;
