// Gutenberg generated rules
.has-text-align-center {
    text-align: center;
}

.has-text-align-right {
    text-align: right;
}

.markup {
    margin-bottom: rhythm(4);
    @include base;

    code {
        background-color: whitesmoke;
        border: 1px solid lightgrey;
        color: rgb(224, 30, 90);
        border-radius: 3px;
        padding: 2px 5px;
        margin: 0 3px;
        font-size: 90%;
    }

    &:last-child,
    p:last-of-type {
        margin-bottom: 0;
    }

    p + blockquote {
        margin-top: rhythm(6);
    }

    a:not(.button) {
        font-weight: $bold;
        @include link($primary, $underline: true);
    }

    strong {
        font-weight: $bold;
    }

    img {
        display: block;
        height: auto; // Required to combat height attributes
        margin: rhythm(4) auto;
    }

    ul,
    ol {
        text-align: left;
        padding-left: 1em;
        margin: 0 0 rhythm(4);

        &:last-child {
            margin-bottom: 0;
        }
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: disc;
    }

    table {
        width: 100%;
        text-align: left;
        margin-bottom: rhythm(4);

        &:last-child {
            margin-bottom: 0;
        }
    }

    tr:nth-child(odd) {
        background-color: $white;
    }

    th,
    td {
        padding: rhythm(1);
    }

    blockquote {
        // @extend .quote;

        @media (max-width: 600px) {
            margin-inline: 0;
        }
    }

    blockquote:last-child {
        margin-bottom: 0;
    }

    .alignright {
        float: right;
        margin: 0 0 rhythm(2) rhythm(4);
        max-width: 50%;

        img {
            margin-top: 0;
            margin-bottom: rhythm(2);
        }

        @media (max-width: 800px) {
            float: none;
            margin: rhythm(4) auto;
            max-width: 100% !important;
            width: 100% !important;

            img {
                width: 100%;
            }
        }
    }

    .alignleft {
        float: left;
        margin: 0 rhythm(4) rhythm(2) 0;
        max-width: 50%;

        img {
            margin-top: 0;
            margin-bottom: rhythm(2);
        }

        @media (max-width: 800px) {
            float: none;
            margin: rhythm(4) auto;
            max-width: 100% !important;
            width: 100% !important;

            img {
                width: 100%;
            }
        }
    }

    .alignnone,
    .aligncenter {
        max-width: 100% !important;
        margin: 0 0 rhythm(2) 0;

        @media (max-width: 800px) {
            width: 100% !important;

            img {
                width: 100%;
            }
        }
    }

    .aligncenter {
        margin-inline: auto;
    }

    .wp-caption:not(.align*) {
        width: auto !important; // Required to override inline styles
        margin: rhythm(8) 0;

        > p:empty {
            display: none; // for some reason wp caption is adding a blank p tag
        }
    }

    .wp-caption-text {
        @include zeta;
        font-style: italic;
        margin-bottom: 0;
    }
}
