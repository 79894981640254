.layout {
    margin: rhythm(6) 0;

    @media (max-width: 800px) {
        margin: rhythm(2) 0;
    }

    &[class*='bg--'] {
        padding: rhythm(6) 0;
        margin: 0;

        @media (max-width: 800px) {
            padding: rhythm(2) 0;
        }
    }
}

.block-layouts .row:not(:first-of-type) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: rhythm(6);
    }
}
