// Base Styles

*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-family: $type-body-font;
    font-size: $type-body;
    line-height: $type-leading;

    background-color: $bg;
    color: $black;

    // Source: https://medium.com/@MateMarschalko/improving-font-rendering-with-css-3383fc358cbc
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;

    // background-image: url('../../../../images/clouds.png');
    // background-size: 100%;
}

// Links

a {
    color: inherit;
    text-decoration: none;
}

// Images

img,
svg,
.lazy {
    vertical-align: top;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
    padding: 0;
    margin: 0;
}
