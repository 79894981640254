// This is the shame file. If you put anything in here it needs some documentation with it.

// The cookie consent window has a box shadow on it
.cc-window {
    @include depth(5);
}

@media (min-width: 800px) {
    .spacer {
        padding-bottom: 25vh;
    }
}
