.clouds {
    height: 100vh;
    width: 50vw;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;

    img {
        object-fit: contain;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
    }

    @media (max-width: 800px) {
        width: 100vw;
        top: auto;
        bottom: 0;
    }
}

@media (max-width: 800px) {
    .column {
        position: relative;
    }
}
